import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import content from "../../data/content.json"
import Button from "../components/action-button"
import { useStaticQuery, graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image"

const Header = ({ openModal }) => {
  const query = useStaticQuery(
    graphql`
      query {
        company: airtable(table: { eq: "Basics" }) {
          data {
            Brand
            Description
            Phone
            Icon {
              localFiles {
                childImageSharp {
                  gatsbyImageData(
                    sizes: ""
                    layout: FULL_WIDTH
                    aspectRatio: 1
                    placeholder: NONE
                  )
                }
              }
            }
          }
        }
        site: airtable(table: { eq: "Sites" }) {
          data {
            Header
            Subtitle
            Cover {
              localFiles {
                childImageSharp {
                  gatsbyImageData(
                    sizes: ""
                    layout: FULL_WIDTH
                    aspectRatio: 1
                    placeholder: NONE
                    transformOptions: { cropFocus: WEST }
                  )
                }
              }
            }
            Cover {
              localFiles {
                childImageSharp {
                  fluid(quality: 90, maxWidth: 1920) {
                    base64
                    srcWebp
                    srcSetWebp
                    originalImg
                    originalName
                  }
                }
              }
            }
            Header_type
            Logo_type
            Actions
          }
        }
      }
    `
  )

  const coverImage = [
    query.site.data.Cover.localFiles[0].childImageSharp.fluid,
    `linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.6))`,
  ].reverse()
  const featuredImage =
    query.site.data.Cover.localFiles[0].childImageSharp.gatsbyImageData
  const headerType = query.site.data.Header_type
  const brandIcon =
    query.company.data.Icon.localFiles[0].childImageSharp.gatsbyImageData
  
    const logoType =
    query.site.data.Logo_type === "Icon + Brand name" ? (
      <div className="h-10 w-10">
        <GatsbyImage
          image={brandIcon}
          imgStyle={{
            borderRadius: "50%",
            borderWidth: "2px",
            borderColor: "white",
          }}
          formats={["AUTO", "WEBP", "AVIF"]}
          alt={query.company.data.Brand}
        ></GatsbyImage>
      </div>
    ) : (
      ""
    )

  const phoneAction = query.site.data.Actions === "📞 Call" ? (
    <button className="btn btn-white flex flex-row space-x-2 items-center justify-center ">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="h-5 w-5"
        viewBox="0 0 20 20"
        fill="currentColor"
        alt="phone"
      >
        <path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z" />
      </svg>
      <a
        href={`tel:${query.company.data.Phone}`}
      >{`Call us at ${query.company.data.Phone}`}</a>
    </button>
  ) : (
    ""
  )
  return (
    <header>
      {headerType === "Cover" ? (
        <BackgroundImage
          Tag="section"
          fluid={coverImage}
          style={{ backgroundColor: "#000000" }}
        >
          <div className="container-default py-4 lg:py-10 text-center text-white flex flex-col space-y-20">
            <div className="flex flex-row items-start space-x-2">
              {logoType}
              <h1 className="text-2xl filter drop-shadow-lg text-left">
                <Link to="/">{query.company.data.Brand}</Link>
              </h1>
            </div>
            <div className="flex flex-col items-center justify-between lg:space-x-28 pb-7">
              <div className="w-full lg:w-2/3 space-y-6 lg:space-y-10">
                <h1 className="filter drop-shadow-lg">
                  {query.site.data.Header}
                </h1>
                <p className="text-2xl font-bold max-w-prose text-white filter drop-shadow-lg">
                  {query.site.data.Subtitle}
                </p>
                <div className="flex flex-col justify-center md:flex-row md:space-x-4 space-y-4 md:space-y-0">
                  <Button openModal={openModal} content={content} />
                  {phoneAction}
                </div>
              </div>
            </div>
          </div>
        </BackgroundImage>
      ) : (
        <div className="bg-gradient-to-r from-blue-50 to-purple-50">
          <div className="container-default pt-7">
            <div>
              <div className="flex flex-row items-start space-x-2">
              {logoType}
                <h1 className="text-2xl filter drop-shadow-lg text-left">
                  <Link to="/">{query.company.data.Brand}</Link>
                </h1>
              </div>
            </div>
            <div className="flex flex-col lg:flex-row items-center justify-between lg:space-x-28 pb-7 pt-16 md:pt-8 lg:pt-0">
              <div className="w-full lg:w-1/2 space-y-8 pb-7 lg:pb-0">
                <h1 className="">{query.site.data.Header}</h1>
                <p className="text-xl text-grey-500 max-w-prose">
                  {query.site.data.Subtitle}
                </p>
                <div className="flex flex-col md:flex-row md:space-x-4 space-y-4 md:space-y-0">
                  <Button openModal={openModal} content={content} />
                  {phoneAction}
                </div>
              </div>
              <div className="lg:w-1/2 justify-center">
                <GatsbyImage
                  image={featuredImage}
                  imgStyle={{
                    borderRadius: "50%",
                    borderWidth: "8px",
                    borderColor: "white",
                  }}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt={query.company.data.Brand}
                ></GatsbyImage>
              </div>
            </div>
          </div>
        </div>
      )}
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
